<template>
  <div class="menu onest flex column justify-between" :class="{'open': isOpen}">
    <nav class="flex column">
      <template v-for="item in navItems">
        <div class="nav-item relative" :class="{'active': router.name === item.name}">
          <RouterLink :to="item.path" class="no-underline">
            <p>{{ item.title }}</p>
            <div class="circle absolute" :class="{'active': router.name === item.name}"></div>
          </RouterLink>
        </div>
      </template>
    </nav>
    <div class="menu__footer gtwalsh flex column">
      <div class="flex justify-between">
        <a class="no-underline" href="mailto:info@ater-tech.com">info@ater-tech.com</a>
        <a class="underline" href="https://www.linkedin.com/company/ater-tech/" target="_blank">LinkedIn</a>
      </div>
      <div class="flex justify-between">
        <p>Ⓒ 2025, Ater-Tech Limited</p>
        <RouterLink to="/privacy" class="no-underline">Privacy policy</RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import navItems from "@/constants/nav";

const router = useRoute();

const props = defineProps({
  isOpen: Boolean
})
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  z-index: 99;
  height: calc(100% - 74px);
  width: 100%;
  background: $black;
  padding: 12px 20px 20px;
  left: 100%;
  opacity: 0;
  transition: all 0.3s ease;

  &.open {
    left: 0;
    opacity: 1;
  }
}

.menu__footer {
  gap: 14px;
  p, a, a:active, a:visited, a:focus {
    color: $white;
  }
  & > div:nth-child(1) {
    a {
      font-size: 16px;
      line-height: 24px;
      opacity: 0.8;
    }
  }
  & > div:nth-child(2) {
    p, a {
      font-size: 14px;
      line-height: 20px;
      opacity: 0.5;
    }
  }
}

nav {
  overflow-y: scroll;
  margin-bottom: 20px;
}

.nav-item {
  color: $white;
  font-size: 30px;
  line-height: normal;
  opacity: 0.8;
  padding: 28px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  a {
    &, &:active, &:visited, &:focus {
      color: $white;
    } 
  }
}

.circle {
  width: 6px;
  height: 6px;
  background: $white;
  opacity: 0.4;
  border-radius: 100%;
  top: 50%;
  right: 7px;
  transform: translate(0, -50%);
  &.active {
    width: 10px;
    height: 10px;
    opacity: 1;
    background: linear-gradient(90deg, #F00 0%, #FF3110 33%, #FF7024 78%, #FF892D 100%), #FFF;
  }
}
</style>